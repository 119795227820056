import { navigate } from "gatsby";
import Actions from "./Reducers/Actions";
import Catchify from "./Utils/Catchify";
import Api from "./Api";
import { ApiAdapter } from "./ApiAdapter";
import * as Survey from "./Contexts/SurveyContext";
import { USER_TYPE } from "./constants";
import { setAuthToken } from "./Utils/apAxios";
import { setPrivacyEnable } from "./SurveyApi";
let windowGlobal = typeof window !== "undefined" && window;

export default dispatch => ({
  navActions: {
    push: Catchify(async (url, param) => {
      if (!param) {
        navigate(`/${url}`);
      } else {
        navigate(`/${url}`, param);
      }
    }),
  },

  appActions: {
    setLang: Catchify(async lang => {
      dispatch({ type: Actions.SET_LANG, payload: lang });
    }),

    autoLogin: async () => {
      try {
        let token = windowGlobal.localStorage.getItem("token");

        if (!token) {
          throw "local data not found";
        }

        let profile = {
          username: "張慧涵",
          role: "admin",
          RoldId: "1",
        };

        Api.setToken("fake-token");
        dispatch({ type: Actions.LOGIN, payload: profile });
      } catch (err) {
        Api.setToken(null);
        windowGlobal.localStorage.removeItem("token");
        throw err;
      }
    },

    ssoLogin: async uid => {
      try {
        const API = new ApiAdapter();
        let response = API.ssoVerify(uid);
        Promise.resolve(response).then(
          function(res) {
            dispatch({ type: "SSO_VERIFY", payload: res });
            if (res.code && res.code === "200") {
              let profile = {
                userId: res.data.UserId,
                userName: res.data.UserName,
                token: res.data.Token,
                RoleId: res.data.RoleId,
                role: res.data.RoleName,
              };

              Api.setToken(profile.token);
              dispatch({ type: Actions.LOGIN, payload: profile });
              try {
                windowGlobal.localStorage.setItem("token", profile.token);
                windowGlobal.localStorage.setItem(
                  "profile",
                  JSON.stringify(profile)
                );
                setTimeout(function() {
                  navigate("/", { profile: profile });
                }, 50);
                //navigate("/");
                //windowGlobal.location.reload();
                //windowGlobal.location.replace("/");
              } catch (err) {
                if (
                  err.name === "QUOTA_EXCEEDED_ERR" ||
                  err.name === "QuotaExceededError"
                ) {
                  alert(
                    "您的瀏覽器暫存區容量已滿，目前無法儲存答題內容，請手動清除瀏覽紀錄或改用無痕瀏覽器填寫問卷。"
                  );
                }
              }
            } else {
              //not response 200
              // windowGlobal.localStorage.removeItem("token");
              // windowGlobal.localStorage.removeItem("profile");
              // 印出js路徑與行數
              console.debug("各路工程師想要使用者回老家");
              navigate("/");
            }
          },
          function(res) {
            // not called
          }
        );
      } catch (err) {
        console.debug("各路工程師想要使用者回老家");
        navigate("/");
        throw err;
      }
    },
    // CDP問卷SSO登入
    ssoCdpLogin: async (ssoToken, urlModule) => {
      try {
        if (ssoToken) {
          const API = new ApiAdapter();
          // 調用 SSO Token API
          let response = await API.getSsoToken(ssoToken);
          console.log("[CDP][SSO][Login]:response:", response);
          if (response.code === "200") {
            const {
              token,
              userId,
              userName,
              telephone,
              roleId,
              userCode,
              type,
              typeName,
              pagination,
              filters,
            } = response.data;

            if (token) {
              // 儲存 Token 和用戶資料
              const profile = {
                token: token,
                userId: userId,
                userName: userName,
                telephone: telephone,
                roleId: roleId,
                userCode: userCode,
                type: type,
                typeName: typeName,
                pagination: pagination,
                filters: filters,
              };
              console.log("profile", profile);

              window.localStorage.setItem("token", token);
              window.localStorage.setItem("profile", JSON.stringify(profile));
              dispatch({ type: Actions.LOGIN, payload: profile });
              navigate(`/urls/${urlModule}`);
            } else {
              throw new Error("無法獲取 Token");
            }
          }
        } else {
          throw new Error("無效的登錄請求");
        }
      } catch (error) {
        navigate(`/`); // 登入失敗就回登入頁面
        const message = `登入失敗: ${error.message || "發生錯誤"}`;
        alert(message); // 或者使用其他錯誤處理方式
        throw error;
      }
    },
    // 設定Token
    setToken: async (token, profile) => {
      try {
        const API = new ApiAdapter();
        Api.setToken(token);
        // 相容新引進的apAxios Token
        setAuthToken(token);
      } catch (error) {
        console.log("setToken error", error);
      }
    },
    // 設定登入者資訊
    setProfile: async profile => {
      try {
        windowGlobal.localStorage.setItem("profile", JSON.stringify(profile));
        dispatch({ type: Actions.LOGIN, payload: profile });
      } catch (error) {
        console.log("setUserProfile error", error);
      }
    },
    // change to account & password mode
    getOTPVerify: async (account, password) => {
      try {
        const API = new ApiAdapter();
        let response = API.getOTPVerify(account, password);
        return response;
      } catch (err) {
        throw err;
      }
    },
    checkOTPVerify: async (account, password, verifyCode) => {
      try {
        const API = new ApiAdapter();
        let response = API.checkOTPVerify(account, password, verifyCode);
        Promise.resolve(response).then(function(res) {
          // dispatch({ type: "VERIFY_OTP", payload: res });
          if (res.code === "108" || res.code === "109") {
            Api.setToken(res.data.Token);
            windowGlobal.localStorage.setItem("token", res.data.Token);
          }
          if (res.code === "200") {
            let profile = {
              userId: res.data.UserId,
              userName: res.data.UserName,
              token: res.data.Token,
              RoleId: res.data.RoleId,
              role: "admin",
              UserCode: res.data.UserCode,
            };
            Api.setToken(profile.token);
            try {
              windowGlobal.localStorage.setItem("token", profile.token);
              windowGlobal.localStorage.setItem(
                "profile",
                JSON.stringify(profile)
              );
              Survey.Actions.setInternalLocalStorageToken(true);
              dispatch({ type: Actions.LOGIN, payload: profile });
            } catch (err) {
              if (
                err.name === "QUOTA_EXCEEDED_ERR" ||
                err.name === "QuotaExceededError"
              ) {
                alert(
                  "您的瀏覽器暫存區容量已滿，目前無法儲存答題內容，請手動清除瀏覽紀錄或改用無痕瀏覽器填寫問卷。"
                );
              }
            }
          }
        });
        return response;
      } catch (err) {
        throw err;
      }
    },
    login: async ({ account, phone }) => {
      try {
        let profile = {
          username: "",
          token: "fake-token",
          role: "admin",
          RoleId: "1",
          account,
        };
        Api.setToken(profile.token);
        dispatch({ type: Actions.LOGIN, payload: profile });

        try {
          windowGlobal.localStorage.setItem("token", profile.token);
          windowGlobal.localStorage.setItem("profile", JSON.stringify(profile));
        } catch (err) {
          if (
            err.name === "QUOTA_EXCEEDED_ERR" ||
            err.name === "QuotaExceededError"
          ) {
            alert(
              "您的瀏覽器暫存區容量已滿，目前無法儲存答題內容，請手動清除瀏覽紀錄或改用無痕瀏覽器填寫問卷。"
            );
          }
        }

        return profile;
      } catch (err) {
        Api.setToken(null);
        dispatch({ type: Actions.LOGOUT, payload: null });
        windowGlobal.localStorage.removeItem("token");
        windowGlobal.localStorage.removeItem("profile");
        throw err;
      }
    },

    logout: async account => {
      try {
        let userAccount = account;
        // 預先判斷是否為SSO用戶
        var userType = USER_TYPE.MGT;
        if (!userAccount) {
          const profile = JSON.parse(
            windowGlobal.localStorage.getItem("profile")
          );
          if (profile == null) return;
          userAccount = profile.UserCode;
          console.debug("profile", profile);
          userType = profile.type;
        }
        console.log("userType", userType);
        const API = new ApiAdapter();

        let response = API.logOut(userAccount);
        windowGlobal = typeof window !== "undefined" && window;
        windowGlobal.localStorage &&
          windowGlobal.localStorage.removeItem("token");
        windowGlobal.localStorage &&
          windowGlobal.localStorage.removeItem("profile");
        Api.setToken(null);

        Promise.resolve(response).then(
          function(res) {
            if (res.code === "200") {
              windowGlobal = typeof window !== "undefined" && window;
              windowGlobal.localStorage &&
                windowGlobal.localStorage.removeItem("token");
              windowGlobal.localStorage &&
                windowGlobal.localStorage.removeItem("profile");
              Api.setToken(null);
              dispatch({ type: Actions.LOGOUT, payload: null });

              // 如果現在已經是在/auth這個頁面，就不要再跳轉
              if (windowGlobal.location.pathname === "/auth") {
                return;
              }
              // TODO: 如果是SSO用戶，呈現過期訊息
              if (userType === USER_TYPE.CDP) {
                return;
              }
              navigate(`/`);
            }
          },
          function(res) {
            alert(res.message);
          }
        );
      } catch (err) {
        throw err;
      }
    },

    echo: async () => {
      return await Api.echo("hello");
    },
    verifyUser: async (account, phone) => {
      try {
        const API = new ApiAdapter();
        let response = API.verifyUser(account, phone);
        return response;
      } catch (err) {
        throw err;
      }
    },

    changePassword: async (userId, password) => {
      try {
        const API = new ApiAdapter();
        let response = API.changePassword(userId, password);
        return response;
      } catch (err) {
        throw err;
      }
    },

    setTheme: payload => {
      dispatch({ type: Actions.SET_THEME, payload });
    },
  },
});
